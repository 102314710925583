/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/
@mixin col($col-break, $col-count, $gutter: margin()) {
	$cont-s: inherit;
	@if $col-break == xs {
		$cont-s: map-get($grid-num, xs);
	} @else if $col-break == sm {
		$cont-s: map-get($grid-num, sm);
	} @else if $col-break == md {
		$cont-s: map-get($grid-num, md);
	} @else if $col-break == lg {
		$cont-s: map-get($grid-num, lg);
	} @else if $col-break == xl {
		$cont-s: map-get($grid-num, xl);
	} @else if $col-break == xxl {
		$cont-s: map-get($grid-num, xxl);
	}
	$num: $col-count - 1;
	$margin: floor-decimal((($gutter * $num) / $cont-s) * 100%, 2);
	column-gap: floor-decimal(($margin / $num), 2);
	& > * {
		flex-basis: floor-decimal((100 - $margin) / $col-count);
	}
}


/* column3 */
.l-col-3 {
	&-xs {
		@include media-breakpoint-up(xs) {
			@include flex-wrap;
			@include col(xs, 2, 10);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 48);
		}
		@include media-breakpoint-down(xxs) {
			& > * {
				@include m-a;
			}
		}
	}
	&-sm {
		@include media-breakpoint-up(sm) {
			@include flex-wrap;
			@include col(sm, 2, 20);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(xs) {
			& > * {
				@include m-a;
			}
		}
	}
	&-md {
		@include media-breakpoint-up(md) {
			@include flex-wrap;
			@include col(md, 3, 10);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(sm) {
			& > * {
				@include m-a;
			}
		}
	}
	&-lg {
		@include media-breakpoint-up(lg) {
			@include flex-wrap;
			@include col(lg, 3, 40);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 3, 60);
		}
		@include media-breakpoint-down(sm) {
			& > * {
				@include m-a;
			}
		}
	}
}

/* column4 */
.l-col-4 {
	&-xs {
		@include media-breakpoint-up(xs) {
			@include flex-wrap;
			@include col(xs, 2, 10);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 4 , 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 4, 30);
		}
		@include media-breakpoint-up(xxl) {
			@include col(xxl, 4, 50);
		}
		@include media-breakpoint-down(xxs) {
			& > * {
				@include m-a;
			}
		}
	}
	&-sm {
		@include media-breakpoint-up(sm) {
			@include flex-wrap;
			@include col(xs, 2, 10);
		}
		@include media-breakpoint-up(lg) {
			@include col(lg, 4 , 20);
		}
		@include media-breakpoint-up(xl) {
			@include col(xl, 4, 30);
		}
		@include media-breakpoint-up(xxl) {
			@include col(xxl, 4, 50);
		}
		@include media-breakpoint-down(xxs) {
			& > * {
				@include m-a;
			}
		}
	}
}