/*--------------------------------------------------------------------/
		modal
/--------------------------------------------------------------------*/
.body-fixed {
	position: fixed;
	width: 100%;
	height: 100%;
}

@keyframes menu-bar01 {
	0% {
		transform: translateY(7px) rotate(45deg);
	}
	50% {
		transform: translateY(7px) rotate(0);
	}
	100% {
		transform: translateY(0) rotate(0);
	}
}
@keyframes menu-bar02 {
	0% {
		transform: translateY(-7px) rotate(-45deg);
	}
	50% {
		transform: translateY(-7px) rotate(0);
	}
	100% {
		transform: translateY(0) rotate(0);
	}
}

@keyframes active-menu-bar01 {
	0% {
		transform: translateY(0) rotate(0);
	}
	50% {
		transform: translateY(7px) rotate(0);
	}
	100% {
		transform: translateY(7px) rotate(45deg);
	}
}
@keyframes active-menu-bar03 {
	0% {
		transform: translateY(0) rotate(0);
	}
	50% {
		transform: translateY(-7px) rotate(0);
	}
	100% {
		transform: translateY(-7px) rotate(-45deg);
	}
}

/* スマホメニュー */
.close-btn {
	@media (min-width: 1417px) {
		a {
			@include dec-none;
		}
	}
}

.hamburger {
	position: fixed;
	top: 0;
	right: 0;
	z-index: z-index(hamburger);
	@media (min-width: 1417px) {
		display: none;
	}
	.open &-btn {
		background: transparent;
	}
	&-btn {
		position: relative;
		width: 64px;
		height: 64px;
		padding: 18px;
		cursor: pointer;
		display: block;
		transition: all 0.4s;
		box-sizing: border-box;
		background: $main_c;
		& span {
			display: inline-block;
			transition: all 0.4s;
			box-sizing: border-box;
			position: absolute;
			left: 24px;
			width: 18px;
			height: 1px;
			background-color: $white;
			border-radius: 2px;
			&:nth-of-type(1) {
				top: 25px;
				animation: menu-bar01 0.75s forwards;
			}
			&:nth-of-type(2) {
				top: 32px;
				transition: all 0.25s 0.25s;
				opacity: 1;
			}
			&:nth-of-type(3) {
				bottom: 24px;
				animation: menu-bar02 0.75s forwards;
			}
		}
		&.active {
			& span {
				background-color: $white;
				&:nth-of-type(1) {
					animation: active-menu-bar01 0.75s forwards;
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-of-type(3) {
					animation: active-menu-bar03 0.75s forwards;
				}
			}
		}
	}
}

.sp-nav {
	@media (min-width: 1417px) {
		display: none;
	}
	@include media-breakpoint-down(lg) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: $main_c;
		visibility: hidden;
		z-index: z-index(sp-nav);
		body.open & {
			cursor: "", auto;
			@include transition(0.5);
			visibility: visible;
			opacity: 1;
		}
		&__inner {
			width: 80%;
			height: 100%;
			margin: 4rem 10% 0;
			padding-bottom: 8rem;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			position: relative;
		}
		&__tit {
			width: clamp(120px, 60%, 200px);
			@include m-a;
			margin-bottom: 2rem;
			opacity: 0;
			body.open & {
				opacity: 1;
				@include transition(all, 0.5, ease, .3);
			}
		}
		/* クローズボタン　*/
		& .close-wrap {
			margin-top: 1rem;
			opacity: 0;
			body.open & {
				opacity: 1;
				@include transition(all, 0.5, ease, 1.5);
			}
			a {
				line-height: 1.2;
				font-weight: 400;
				text-align: center;
				border: 1px solid $white;
				@include dec-none;
				width: 100%;
				display: block;
				color: $white;
				height: 60px;
				line-height: 60px;
				@include f-family(font01);
				&::before {
					content: "×";
					margin-right: 0.3rem;
					@include f-family(font03);
				}
			}
		}
	}
}

.sp-nav__list {
	& > li {
		width: 100%;
		margin: 0 auto;
		margin-top: -15px;
		text-align: left;
		opacity: 0;
		border-bottom: 1px solid $other_c1;
		& a {
			display: block;
			color: $other_c1;
			text-decoration: none;
			height: 60px;
			line-height: 60px;
			box-sizing: border-box;
			padding-left: 1rem;
			position: relative;
		}
	}
	//二階層目のメニュー
	& .sub-menu {
		display: none;
		& li {
			border-top: 1px dotted $white;
			a {
				padding-left: 1rem;
				&::before {
					content: "-";
					margin-right: 0.75rem;
				}
			}
		}
	}
}

//二階層メニュー
.sp-lv2 {
	position: relative;
	width: 100%;
	a[href="javascript:void(0);"] {
		&::before,
		&::after {
			font-style: normal;
			position: absolute;
			top: 50%;
			right: 8px;
			display: block;
			width: 10px;
			height: 1px;
			content: "";
			@include transition;
			background-color: $white;
		}
		&::before {
			transform: rotate(0deg);
		}
		&::after {
			transform: rotate(90deg);
		}
		&.active {
			&::before {
				display: none;
			}
			&::after {
				top: 50%;
				transform: rotate(180deg);
			}
		}
	}
}

.sp-nav__contact {
	margin-top: 1rem;
	opacity: 0;
	@include f-family(font01);
	body.open & {
		opacity: 1;
		@include transition(all, 0.5, ease, 1.2);
	}
	a {
		padding: .75em 0;
		@include dec-none;
		display: block;
		background: $other_c3;
		border: 1px solid $other_c1;
		color: $main_c;
		@include f-w(700);
		text-align: center;
		@include line-h(1);
		@include f-s_xs(1.14, 8);
		@include media-breakpoint-up(lg) {
			@include f-size(26);
		}
		span {
			@include icon(mail);
			&::before {
				font-size: 1.1em;
				margin-right: 0.3em;
			}
		}
	}
}

.sp-nav__flex {
	@include media-breakpoint-up(md) {
		@include flex-between;
		column-gap: 1.6%;
		& > * {
			width: 49.2%;
		}
	}
}

//電話ボタン
.sp-nav__tel {
	margin-top: 1rem;
	padding: 1rem 0;
	opacity: 0;
	background: $white;
	@include f-family(font02);
	height: 60px;
	body.open & {
		opacity: 1;
		@include transition(all, 0.5, ease, 1.3);
	}
	span {
		line-height: 1.2;
		@include f-w(700);
		text-align: center;
		@include f-s_xs(1.5, 4);
		display: block;
		color: $black;
		@include icon(phone, before);
		@include media-breakpoint-up(lg) {
			@include f-size(28);
		}
		&::before {
			line-height: 1;
			font-size: 0.75em;
			margin-right: 0.2rem;
			padding-bottom: 0.5rem;
			font-weight: 100;
			color: $main_c;
		}
	}
	.tel-link a {
		color: $black;
	}
}

.sp-nav__line {
	margin-top: 1rem;
	opacity: 0;
	body.open & {
		opacity: 1;
		@include transition(all, 0.5, ease, 1.4);
	}
	a {
		display: block;
		align-items: center;
		background: $other_c3;
		padding: 0.75rem 0;
		@include dec-none;
		color: $black;
		@include f-w(700);
		height: 60px;
		@include f-s_xs(0.85,4);
		@include media-breakpoint-up(md) {
			@include f-size(17);
		}
		span {
			display: flex;
			justify-content: center;
			align-items: center;
			column-gap: 0.4rem;
		}
		img {
			max-width: 40px;
		}
	}
}

body.open .sp-nav__list > li {
	opacity: 1;
	margin-top: 0;
	@for $num from 1 through 11 {
		&:nth-child(#{$num}) {
			transition: 0.5s ease (0.2s + ($num / 10));
		}
	}
}
