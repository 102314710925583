/*--------------------------------------------------------------------/
	loader
/--------------------------------------------------------------------*/
.loader {
	width: 100vw;
	height: 100vh;
	background: $main_c;
	z-index: z-index(loader);
	position: fixed;
	&__wrap {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__circle {
		width: 0;
		height: 0;
		position: absolute;
		display: block;
		background: $white;
		border-radius: 50%;
	}
	&__logo {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		&__inner {
			@include m-a;
			max-width: 200px;
			max-height: 200px;
			animation: txt_opacity 1.5s forwards;
		}
	}
	&__filter {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: $white;
		z-index: z-index(module, loader-filter);
		opacity: 0;
	}
}
@keyframes txt_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}