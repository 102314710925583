@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1825px) {
  /*====================================================================/
/*--Large PC 以上--
/====================================================================*/
}

@media (min-width: 1637px) {
  /*====================================================================/
/*---PC 以上--
/====================================================================*/
}

@media (min-width: 1057px) {
  /*====================================================================/
/*--XGAサイズ　iPadPro 以上--
/====================================================================*/
}

@media (min-width: 768px) {
  /*====================================================================/
/*--タブレット　iPadmini 以上--
/====================================================================*/
}

@media (min-width: 576px) {
  /*====================================================================/
/*--スマホ 以上--
/====================================================================*/
}

@media (min-width: 376px) {
  /*====================================================================/
/*--iPhone 以上--
/====================================================================*/
}

@media (max-width: 1824px) {
  /*====================================================================/
/*--Large PC 未満--
/====================================================================*/
}

@media (max-width: 1636px) {
  /*====================================================================/
/*--PC 未満--
/====================================================================*/
}

@media (max-width: 1056px) {
  /*====================================================================/
/*--XGAサイズ iPadPro 未満--
/====================================================================*/
}

@media (max-width: 767px) {
  /*====================================================================/
/*--タブレット iPadmini 未満--
/====================================================================*/
}

@media (max-width: 575px) {
  /*====================================================================/
/*--iPhone 以下--
/====================================================================*/
}

@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
/*--スマホ以上 タブレット iPadmini未満--
/====================================================================*/
}

@media (min-width: 768px) and (max-width: 1056px) {
  /*====================================================================/
/*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
/====================================================================*/
}

@media (min-width: 1057px) and (max-width: 1636px) {
  /*====================================================================/
/*--XGAサイズ iPadPro以上 PC未満--
/====================================================================*/
}

@media (min-width: 1637px) and (max-width: 1824px) {
  /*====================================================================/
/*--px以上 Large PC未満--
/====================================================================*/
}

/* ========================================
 * foundation
 ======================================= */
/* html5reset-1.6.1.css */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var, address,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  border-collapse: collapse;
  /* added */
  -webkit-text-size-adjust: none;
  /* added */
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

address {
  font-style: normal;
}

nav ul {
  list-style: none;
}

ul li {
  list-style: none;
}

/* added */
ol li {
  list-style: none;
}

/* added */
blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: middle;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/* Document
 * ========================================================================== */
/**
 * Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  box-sizing: border-box;
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Use a 4-space tab width in all browsers (opinionated).
 * 4. Remove the grey highlight on links in iOS (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  line-height: 1.5;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  tab-size: 4;
  /* 3 */
  -webkit-tap-highlight-color: transparent;
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */
}

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  margin: 0;
}

/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none;
  padding: 0;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */
iframe {
  border-style: none;
}

/**
 * Remove the border on images within links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */
fieldset {
  border: 1px solid #a0a0a0;
  /* 1 */
  padding: 0.35em 0.75em 0.625em;
  /* 2 */
}

/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  /* 1 */
  touch-action: manipulation;
  /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden] {
  display: initial;
}

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?jcu33n");
  src: url("../fonts/icomoon.eot?jcu33n#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?jcu33n") format("truetype"), url("../fonts/icomoon.woff?jcu33n") format("woff"), url("../fonts/icomoon.svg?jcu33n#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-top_arrow:before {
  content: "\e901";
}

.icon-arrow-top:before {
  content: "\e902";
}

.icon-arrow-right:before {
  content: "\e903";
}

.icon-arrow-bottom:before {
  content: "\e904";
}

.icon-arrow-left:before {
  content: "\e905";
}

.icon-link:before {
  content: "\e900";
}

.icon-tag:before {
  content: "\e906";
}

.icon-check:before {
  content: "\e907";
}

.icon-search:before {
  content: "\e908";
}

.icon-search-plus:before {
  content: "\e90c";
}

.icon-phone:before {
  content: "\e909";
}

.icon-home:before {
  content: "\e90b";
}

.icon-line:before {
  content: "\e90d";
}

.icon-mail:before {
  content: "\e911";
}

.icon-pdf:before {
  content: "\e90a";
}

/*--------------------------------------------------------------------/
	body
/--------------------------------------------------------------------*/
html {
  font-size: 100%;
}

body {
  font-size: 0.88rem;
  letter-spacing: 0.1em;
  font-family: -apple-system, BlinkMacSystemFont, 'Noto Sans JP', "Hiragino Sans", "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "游ゴシック", "Yu Gothic", "游ゴシック体", YuGothicM, YuGothic, "メイリオ", Meiryo, sans-serif;
  line-height: 1.75;
  font-weight: 400;
  word-wrap: break-word;
  color: #232323;
  background: #fff;
  overflow-x: hidden;
}

@media (min-width: 576px) {
  body {
    font-size: 0.94rem;
  }
}

@media (min-width: 1057px) {
  body {
    font-size: 1rem;
  }
}

/*
section:after,
article:after {
	content: "";
	clear: both;
	display: block;
}
*/
/* heading 
----------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  line-height: 1.4;
}

/* a,img
----------------------------------------------------------------*/
a {
  color: #0E0F53;
  vertical-align: top;
}

a:link, a:visited {
  text-decoration: underline;
}

a:hover, a:active {
  text-decoration: none;
}

a img {
  display: block;
  width: 100%;
}

a.hover img {
  transition: all 0.3s ease 0s;
}

a.hover img:hover {
  opacity: .6;
}

img {
  max-width: 100%;
  display: block;
}

/* tel-link */
.tel-link a {
  text-decoration: none !important;
}

/* scroll-position */
[id*="link-"],
.scroll-position {
  padding-top: 64px;
  margin-top: -64px;
}

@media (min-width: 1637px) {
  [id*="link-"],
  .scroll-position {
    padding-top: 6rem;
    margin-top: -6rem;
  }
}

/* scroll-bar */
.scroll-bar::-webkit-scrollbar {
  width: 8px;
}

.scroll-bar::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-bar::-webkit-scrollbar-thumb {
  background: #C9CED2;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #fff;
}

/* CSS Document */
/*====================================================================

	common.css

=====================================================================*/
/*--------------------------------------------------------------------/
	loader
/--------------------------------------------------------------------*/
.loader {
  width: 100vw;
  height: 100vh;
  background: #1D2087;
  z-index: 10;
  position: fixed;
}

.loader__wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader__circle {
  width: 0;
  height: 0;
  position: absolute;
  display: block;
  background: #fff;
  border-radius: 50%;
}

.loader__logo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader__logo__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 200px;
  max-height: 200px;
  animation: txt_opacity 1.5s forwards;
}

.loader__filter {
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
  opacity: 0;
}

@keyframes txt_opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 7;
  width: 100%;
  background: #fff;
  transition: all 0.3s ease-in 0s;
  height: 64px;
}

@media (min-width: 1417px) {
  .header {
    height: 70px;
  }
}

.header.fixed {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.h-inner {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
}

@media (min-width: 1057px) {
  .h-inner {
    padding-left: 40px;
  }
}

.h-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-left {
  padding-top: 1rem;
  transition: all 0.3s ease-in 0s;
}

@media (min-width: 1417px) {
  .h-left {
    padding-top: 0.6rem;
  }
}

.h-logo {
  display: inline-block;
  font-weight: normal;
  letter-spacing: 0;
  text-align: left;
  max-width: 160px;
}

.h-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/*--------------------------------------------------------------------/
		modal
/--------------------------------------------------------------------*/
.body-fixed {
  position: fixed;
  width: 100%;
  height: 100%;
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(7px) rotate(45deg);
  }
  50% {
    transform: translateY(7px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar02 {
  0% {
    transform: translateY(-7px) rotate(-45deg);
  }
  50% {
    transform: translateY(-7px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(7px) rotate(0);
  }
  100% {
    transform: translateY(7px) rotate(45deg);
  }
}

@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-7px) rotate(0);
  }
  100% {
    transform: translateY(-7px) rotate(-45deg);
  }
}

/* スマホメニュー */
@media (min-width: 1417px) {
  .close-btn a {
    text-decoration: none !important;
  }
}

.hamburger {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
}

@media (min-width: 1417px) {
  .hamburger {
    display: none;
  }
}

.open .hamburger-btn {
  background: transparent;
}

.hamburger-btn {
  position: relative;
  width: 64px;
  height: 64px;
  padding: 18px;
  cursor: pointer;
  display: block;
  transition: all 0.4s;
  box-sizing: border-box;
  background: #1D2087;
}

.hamburger-btn span {
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  position: absolute;
  left: 24px;
  width: 18px;
  height: 1px;
  background-color: #fff;
  border-radius: 2px;
}

.hamburger-btn span:nth-of-type(1) {
  top: 25px;
  animation: menu-bar01 0.75s forwards;
}

.hamburger-btn span:nth-of-type(2) {
  top: 32px;
  transition: all 0.25s 0.25s;
  opacity: 1;
}

.hamburger-btn span:nth-of-type(3) {
  bottom: 24px;
  animation: menu-bar02 0.75s forwards;
}

.hamburger-btn.active span {
  background-color: #fff;
}

.hamburger-btn.active span:nth-of-type(1) {
  animation: active-menu-bar01 0.75s forwards;
}

.hamburger-btn.active span:nth-child(2) {
  opacity: 0;
}

.hamburger-btn.active span:nth-of-type(3) {
  animation: active-menu-bar03 0.75s forwards;
}

@media (min-width: 1417px) {
  .sp-nav {
    display: none;
  }
}

@media (max-width: 1636px) {
  .sp-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1D2087;
    visibility: hidden;
    z-index: 8;
    /* クローズボタン　*/
  }
  body.open .sp-nav {
    cursor: "", auto;
    transition: 0.5 0.3s ease 0s;
    visibility: visible;
    opacity: 1;
  }
  .sp-nav__inner {
    width: 80%;
    height: 100%;
    margin: 4rem 10% 0;
    padding-bottom: 8rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }
  .sp-nav__tit {
    width: clamp(120px, 60%, 200px);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    opacity: 0;
  }
  body.open .sp-nav__tit {
    opacity: 1;
    transition: all 0.5s ease 0.3s;
  }
  .sp-nav .close-wrap {
    margin-top: 1rem;
    opacity: 0;
  }
  body.open .sp-nav .close-wrap {
    opacity: 1;
    transition: all 0.5s ease 1.5s;
  }
  .sp-nav .close-wrap a {
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    border: 1px solid #fff;
    text-decoration: none !important;
    width: 100%;
    display: block;
    color: #fff;
    height: 60px;
    line-height: 60px;
    font-family: 'Noto Sans JP', sans-serif;
  }
  .sp-nav .close-wrap a::before {
    content: "×";
    margin-right: 0.3rem;
    font-family: 'Roboto', sans-serif;
  }
}

.sp-nav__list > li {
  width: 100%;
  margin: 0 auto;
  margin-top: -15px;
  text-align: left;
  opacity: 0;
  border-bottom: 1px solid #EBF1FF;
}

.sp-nav__list > li a {
  display: block;
  color: #EBF1FF;
  text-decoration: none;
  height: 60px;
  line-height: 60px;
  box-sizing: border-box;
  padding-left: 1rem;
  position: relative;
}

.sp-nav__list .sub-menu {
  display: none;
}

.sp-nav__list .sub-menu li {
  border-top: 1px dotted #fff;
}

.sp-nav__list .sub-menu li a {
  padding-left: 1rem;
}

.sp-nav__list .sub-menu li a::before {
  content: "-";
  margin-right: 0.75rem;
}

.sp-lv2 {
  position: relative;
  width: 100%;
}

.sp-lv2 a[href="javascript:void(0);"]::before, .sp-lv2 a[href="javascript:void(0);"]::after {
  font-style: normal;
  position: absolute;
  top: 50%;
  right: 8px;
  display: block;
  width: 10px;
  height: 1px;
  content: "";
  transition: all 0.3s ease 0s;
  background-color: #fff;
}

.sp-lv2 a[href="javascript:void(0);"]::before {
  transform: rotate(0deg);
}

.sp-lv2 a[href="javascript:void(0);"]::after {
  transform: rotate(90deg);
}

.sp-lv2 a[href="javascript:void(0);"].active::before {
  display: none;
}

.sp-lv2 a[href="javascript:void(0);"].active::after {
  top: 50%;
  transform: rotate(180deg);
}

.sp-nav__contact {
  margin-top: 1rem;
  opacity: 0;
  font-family: 'Noto Sans JP', sans-serif;
}

body.open .sp-nav__contact {
  opacity: 1;
  transition: all 0.5s ease 1.2s;
}

.sp-nav__contact a {
  padding: .75em 0;
  text-decoration: none !important;
  display: block;
  background: #D9E5FF;
  border: 1px solid #EBF1FF;
  color: #1D2087;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  font-size: calc(1.14rem + 8 * (100vw -  376px) / 1164);
}

@media (min-width: 1057px) {
  .sp-nav__contact a {
    font-size: 1.63rem;
  }
}

.sp-nav__contact a span:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sp-nav__contact a span::before {
  font-size: 1.1em;
  margin-right: 0.3em;
}

@media (min-width: 768px) {
  .sp-nav__flex {
    display: flex;
    justify-content: space-between;
    column-gap: 1.6%;
  }
  .sp-nav__flex > * {
    width: 49.2%;
  }
}

.sp-nav__tel {
  margin-top: 1rem;
  padding: 1rem 0;
  opacity: 0;
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  height: 60px;
}

body.open .sp-nav__tel {
  opacity: 1;
  transition: all 0.5s ease 1.3s;
}

.sp-nav__tel span {
  line-height: 1.2;
  font-weight: 700;
  text-align: center;
  font-size: calc(1.5rem + 4 * (100vw -  376px) / 1164);
  display: block;
  color: #000;
}

.sp-nav__tel span:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1057px) {
  .sp-nav__tel span {
    font-size: 1.75rem;
  }
}

.sp-nav__tel span::before {
  line-height: 1;
  font-size: 0.75em;
  margin-right: 0.2rem;
  padding-bottom: 0.5rem;
  font-weight: 100;
  color: #1D2087;
}

.sp-nav__tel .tel-link a {
  color: #000;
}

.sp-nav__line {
  margin-top: 1rem;
  opacity: 0;
}

body.open .sp-nav__line {
  opacity: 1;
  transition: all 0.5s ease 1.4s;
}

.sp-nav__line a {
  display: block;
  align-items: center;
  background: #D9E5FF;
  padding: 0.75rem 0;
  text-decoration: none !important;
  color: #000;
  font-weight: 700;
  height: 60px;
  font-size: calc(0.85rem + 4 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .sp-nav__line a {
    font-size: 1.06rem;
  }
}

.sp-nav__line a span {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.4rem;
}

.sp-nav__line a img {
  max-width: 40px;
}

body.open .sp-nav__list > li {
  opacity: 1;
  margin-top: 0;
}

body.open .sp-nav__list > li:nth-child(1) {
  transition: 0.5s ease 0.3s;
}

body.open .sp-nav__list > li:nth-child(2) {
  transition: 0.5s ease 0.4s;
}

body.open .sp-nav__list > li:nth-child(3) {
  transition: 0.5s ease 0.5s;
}

body.open .sp-nav__list > li:nth-child(4) {
  transition: 0.5s ease 0.6s;
}

body.open .sp-nav__list > li:nth-child(5) {
  transition: 0.5s ease 0.7s;
}

body.open .sp-nav__list > li:nth-child(6) {
  transition: 0.5s ease 0.8s;
}

body.open .sp-nav__list > li:nth-child(7) {
  transition: 0.5s ease 0.9s;
}

body.open .sp-nav__list > li:nth-child(8) {
  transition: 0.5s ease 1s;
}

body.open .sp-nav__list > li:nth-child(9) {
  transition: 0.5s ease 1.1s;
}

body.open .sp-nav__list > li:nth-child(10) {
  transition: 0.5s ease 1.2s;
}

body.open .sp-nav__list > li:nth-child(11) {
  transition: 0.5s ease 1.3s;
}

@media (min-width: 1417px) {
  .global {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 1416px) {
  .global {
    display: none;
  }
}

.g-nav {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.25em;
}

@media (min-width: 1637px) {
  .g-nav {
    padding-right: 2em;
  }
}

.g-nav > li:not(:last-child) {
  margin-right: 1.25em;
}

@media (min-width: 1637px) {
  .g-nav > li:not(:last-child) {
    margin-right: 2em;
  }
}

.g-nav > li > a {
  vertical-align: middle;
  color: #232323;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  position: relative;
  font-weight: 700;
  letter-spacing: 0.02em;
  padding-top: 7px;
  padding-bottom: 7px;
}

@media (min-width: 1057px) {
  .g-nav > li > a {
    font-size: calc(0.76rem + 2 * (100vw - 1000px) / 540);
  }
}

@media (min-width: 1637px) {
  .g-nav > li > a {
    font-size: 0.94rem;
  }
}

.g-nav > li > a::before {
  content: "";
  background-color: #1D2087;
  width: 0%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.3s ease 0s;
}

.g-nav > li > a:hover::before {
  width: 100%;
}

.header.fixed .g-nav > li > a {
  color: #232323;
}

.header.fixed .g-nav > li > a::before {
  background-color: #1D2087;
}

.header.fixed .g-nav > li > a:hover {
  color: #1D2087;
}

.g-nav__lv2 {
  position: relative;
}

.g-nav__lv2:hover ul {
  top: 46px;
  visibility: visible;
  opacity: 1;
}

.g-nav__lv2 ul {
  position: absolute;
  transition: all 0.3s ease 0s;
  top: 10px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  left: 0;
  z-index: 10;
  width: 240px;
}

.g-nav__lv2 ul a {
  display: block;
  text-decoration: none !important;
  padding: 0.75rem 1rem;
  font-size: 1em;
  color: #009944;
  line-height: 1.4;
  position: relative;
  background: #fff;
  transition: all 0.3s ease 0s;
}

@media (min-width: 576px) {
  .g-nav__lv2 ul a {
    font-size: 0.93em;
  }
}

@media (min-width: 1057px) {
  .g-nav__lv2 ul a {
    font-size: 0.87em;
  }
}

.g-nav__lv2 ul a:hover {
  background: #232729;
  color: #fff;
}

.g-nav__contact {
  height: 70px;
}

.g-nav__contact a {
  text-decoration: none !important;
  background: #1D2087;
  color: #fff;
  height: 100%;
  padding: 7px 1em;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  font-size: calc(0.8rem + 2 * (100vw - 1000px) / 540);
}

@media (min-width: 1637px) {
  .g-nav__contact a {
    font-size: 0.94rem;
    padding: 7px 2em;
  }
}

.g-nav__contact a:hover {
  background: #120CBC;
}

.g-nav__contact a span:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.g-nav__contact a span::before {
  font-size: 1.1em;
  margin-right: 0.3em;
}

.pc-lv2 {
  position: relative;
}

.pc-lv2:hover ul {
  top: 48px;
  visibility: visible;
  opacity: 1;
}

.pc-lv2 ul {
  position: absolute;
  transition: all 0.3s ease 0s;
  top: 30px;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  left: 0;
  z-index: 10;
  width: 240px;
}

.pc-lv2 ul a {
  display: block;
  text-decoration: none !important;
  padding: 0.75rem 1rem;
  font-size: 1em;
  color: #fff;
  line-height: 1.4;
  position: relative;
  background: #232729;
  transition: all 0.3s ease 0s;
}

@media (min-width: 576px) {
  .pc-lv2 ul a {
    font-size: 0.93em;
  }
}

@media (min-width: 1057px) {
  .pc-lv2 ul a {
    font-size: 0.87em;
  }
}

.pc-lv2 ul a:hover {
  background: #1D2087;
  color: #fff;
}

/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
/* wrapper */
/* main */
.main {
  padding-top: 64px;
}

.main::after {
  display: block;
  content: "";
  clear: both;
}

@media (min-width: 1417px) {
  .main {
    padding-top: 70px;
  }
}

.main img {
  display: block;
}

/* contents */
.content {
  overflow: hidden;
}

.l-cont_lg {
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
}

@media (min-width: 1637px) {
  .l-cont_lg {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.l-cont {
  max-width: 1620px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
}

@media (min-width: 1637px) {
  .l-cont {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.l-cont_xxl {
  max-width: 1808px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5%;
  padding-right: 5%;
}

@media (min-width: 1637px) {
  .l-cont_xxl {
    padding-left: 40px;
    padding-right: 40px;
  }
}

/* contents-box */
.l-box {
  width: 100%;
  z-index: 1;
  position: relative;
}

.l-box:not(:last-of-type) {
  margin-bottom: 8rem;
}

@media (min-width: 1057px) {
  .l-box:not(:last-of-type) {
    margin-bottom: 10rem;
  }
}

[class*="bg-box"] {
  padding: 2rem 4%;
}

@media (min-width: 576px) {
  [class*="bg-box"] {
    padding: 2rem 6%;
  }
}

.bg-box--gray {
  background: #F3F5F7;
}

.news-box {
  position: relative;
  background: #fff;
  margin-bottom: 1.5rem;
  text-align: left;
}

@media (min-width: 1057px) {
  .news-box {
    margin-bottom: 2.5rem;
  }
}

.news-box a {
  display: block;
  position: relative;
  text-decoration: none !important;
  height: 100%;
}

.news-box__date {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  position: absolute;
  left: 0.7em;
  line-height: 1;
  letter-spacing: 0.05em;
  color: #1D2087;
  bottom: calc(100% - 0.5em);
  z-index: 2;
  font-size: calc(1.5rem + 8 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .news-box__date {
    font-size: 2rem;
  }
}

.news-box__img {
  overflow: hidden;
}

.news-box__img__inner {
  position: relative;
  width: 100%;
}

.news-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 75%;
  position: relative;
}

.news-box__img__inner > div,
.news-box__img__inner figure,
.news-box__img__inner a,
.news-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.news-box__txt {
  background: #fff;
  padding: 2.5rem 8% 2rem;
  color: #232323;
  position: relative;
}

.news-box__category {
  position: absolute;
  left: 8%;
  top: -0.75rem;
}

.news-box__category .category-item {
  padding: 0.5em 0.75em 0.4em;
  font-weight: 500;
  letter-spacing: 0.05em;
  font-size: 0.81rem;
}

.news-box__tit {
  font-size: calc(1rem + 3 * (100vw -  376px) / 1164);
  margin-bottom: 0.5rem;
}

@media (min-width: 1637px) {
  .news-box__tit {
    font-size: 1.13rem;
  }
}

@media (min-width: 376px) {
  .l-col-4-xs .news-box {
    font-size: calc(0.84rem + 4 * (100vw -  376px) / 1164);
  }
}

@media (min-width: 1057px) {
  .l-col-4-xs .news-box {
    font-size: calc(1rem + 2 * (100vw - 1000px) / 540);
  }
}

/*----------------------------------------------------------------
	component(common)
----------------------------------------------------------------*/
/*	heading
/--------------------------------------------------------------------*/
.heading-tit, .table-tit {
  text-align: center;
  line-height: 1.4;
  padding-bottom: calc(0.5rem + 0.5em);
  margin-bottom: calc(2rem + 0.5em);
  position: relative;
  color: #000;
  font-weight: 700;
  font-size: calc(1.14rem + 6 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .heading-tit, .table-tit {
    font-size: 1.5rem;
  }
}

.heading-tit::after, .table-tit::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  background-color: #1D2087;
  width: 4rem;
  height: 4px;
}

.heading-tit2 {
  line-height: 1.4;
  border-bottom: 1px solid #C9CED2;
  padding-bottom: calc(0.75rem + 0.3em);
  position: relative;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: calc(1.14rem + 2 * (100vw -  376px) / 1164);
}

@media (min-width: 1637px) {
  .heading-tit2 {
    font-size: 1.25rem;
  }
}

.heading-tit2::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: "";
  background-color: #1D2087;
  min-width: 5rem;
  width: 20%;
  height: 1px;
}

.heading-tit4 {
  line-height: 1.4;
  position: relative;
  font-weight: 400;
  font-size: calc(1.14rem + 2 * (100vw -  376px) / 1164);
  text-align: center;
  margin-bottom: 1.5rem;
}

@media (min-width: 1637px) {
  .heading-tit4 {
    font-size: 1.25rem;
  }
}

/* teble用のタイトル */
/* ol
/--------------------------------------------------------------------*/
.common-ol {
  margin-left: 5px;
  counter-reset: ol-num;
}

.common-ol > li {
  line-height: 1.4;
  padding-left: 1.5em;
  position: relative;
}

.common-ol > li::before {
  position: absolute;
  left: 0;
  top: 0;
  counter-increment: ol-num;
  content: counter(ol-num) ".";
  padding-right: 0.5em;
  text-align: center;
}

.common-ol > li:not(:last-child) {
  margin-bottom: 0.7rem;
}

/* ul
/--------------------------------------------------------------------*/
.common-ul {
  text-align: left;
}

.common-ul > li {
  line-height: 1.4;
  padding-left: 1.5rem;
  position: relative;
}

.common-ul > li::before {
  content: "●";
  color: #1D2087;
  position: absolute;
  left: 0;
  top: 0.2em;
  font-size: 0.8em;
  color: #1D2087;
}

.common-ul > li:not(:last-child) {
  margin-bottom: 0.5rem;
}

.common-ul2 {
  margin-left: 5px;
  text-align: left;
}

.common-ul2 li {
  line-height: 1.4;
  padding-left: 1rem;
  position: relative;
}

.common-ul2 li::before {
  content: "・";
  position: absolute;
  color: #232323;
  left: 0;
  top: 0;
}

.common-ul2 li:not(:last-child) {
  margin-bottom: 0.3rem;
}

.caution-ul {
  margin-left: 5px;
}

.caution-ul li {
  line-height: 1.4;
  padding-left: 1.2rem;
  position: relative;
  color: #CE0000;
}

.caution-ul li::before {
  content: "※";
  position: absolute;
  left: 0;
  top: 0;
}

.caution-ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}

/* dl
/--------------------------------------------------------------------*/
.common-dl {
  margin-left: 5px;
  margin-bottom: 2rem;
}

.common-dl dt {
  line-height: 1.4;
}

.common-dl dt:before {
  content: "●";
  padding-right: 0.3em;
}

.common-dl dd:not(:last-child) {
  margin-bottom: 1.5rem;
}

.common-dl2 {
  margin-bottom: 1rem;
}

.common-dl2 dt {
  line-height: 1.4;
  padding-bottom: 0.7rem;
  border-bottom: 1px solid #C9CED2;
  margin-bottom: 0.7rem;
  font-size: 1.28em;
  font-weight: 500;
}

@media (min-width: 576px) {
  .common-dl2 dt {
    font-size: 1.2em;
  }
}

@media (min-width: 1057px) {
  .common-dl2 dt {
    font-size: 1.12em;
  }
}

.common-dl2 dd:not(:last-child) {
  margin-bottom: 2rem;
}

/* table
----------------------------------------------------------------*/
.common-table {
  width: 100%;
  font-size: 1.07em;
  margin-bottom: 2rem;
  line-height: 1.5;
}

@media (min-width: 576px) {
  .common-table {
    font-size: 1em;
  }
}

@media (min-width: 1057px) {
  .common-table {
    font-size: 0.93em;
  }
}

.common-table tr {
  border-bottom: 1px solid #C9CED2;
}

.common-table tr:first-child {
  border-top: 1px solid #C9CED2;
}

.common-table th,
.common-table td.column-1 {
  text-align: left;
  font-weight: 600;
  padding: 1rem 0;
}

@media (min-width: 768px) {
  .common-table th,
  .common-table td.column-1 {
    width: 25%;
    min-width: 180px;
  }
}

@media (min-width: 1637px) {
  .common-table th,
  .common-table td.column-1 {
    width: 225px;
  }
}

@media (max-width: 767px) {
  .common-table th,
  .common-table td.column-1 {
    display: block;
    padding-bottom: .5rem;
  }
}

.common-table td.column-2 {
  padding: 1rem 1.25rem 1rem 0.5rem;
}

@media (max-width: 767px) {
  .common-table td.column-2 {
    display: block;
    padding-top: 0;
    padding-left: 0;
  }
}

/* table press CSS */
.tablepress td,
.tablepress th {
  padding: none;
}

.tablepress tbody td,
.tablepress tfoot th {
  border-top: none !important;
}

/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/
/* txt */
.txt p:not(:last-of-type) {
  margin-bottom: 1rem;
}

/* align */
.align-right {
  float: right;
  margin: 0 0 0.7rem 0.7rem;
}

.align-left {
  float: left;
  margin: 0.7rem 0.7rem 0 0;
}

.align-center {
  display: block;
  margin: 0 auto 0.7rem;
}

/*--------------------------------------------------------------------/
/* common-contact
---------------------------------------------------------------------*/
.common-contact {
  background: #EBF1FF;
  color: #232323;
  padding: clamp(3rem, 6vw, 6rem) 0;
  position: relative;
}

.common-contact::before {
  position: absolute;
  top: 0;
  left: -20%;
  content: "";
  z-index: 1;
  width: 50%;
  height: 100%;
  transform: skewX(20deg);
  background: #D9E5FF;
}

@media (min-width: 1057px) {
  .common-contact::before {
    left: -25%;
  }
}

.common-contact__inner {
  position: relative;
  z-index: 2;
}

@media (min-width: 1057px) {
  .common-contact__inner {
    padding-left: 29.87%;
  }
}

.common-contact__img {
  position: absolute;
  z-index: 2;
}

@media (min-width: 1057px) {
  .common-contact__img {
    width: 23.24%;
    left: 0;
    top: 0;
  }
}

@media (max-width: 1056px) {
  .common-contact__img {
    width: 30%;
    top: calc(100% + 2rem);
    right: 0;
  }
}

@media (max-width: 767px) {
  .common-contact__img {
    width: clamp(100px, 28vw, 30%);
  }
}

.common-contact__flex {
  display: flex;
}

@media (min-width: 768px) {
  .common-contact__flex {
    column-gap: 4.22%;
  }
}

@media (max-width: 767px) {
  .common-contact__flex {
    flex-direction: column;
    row-gap: 2rem;
  }
}

@media (min-width: 768px) {
  .common-contact__left {
    width: 28.87%;
  }
}

.common-contact__info {
  text-align: center;
}

@media (min-width: 768px) {
  .common-contact__info {
    width: 33%;
  }
}

.common-contact__tit {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.5rem;
  line-height: 1;
  font-size: calc(2rem + 28 * (100vw -  376px) / 1164);
}

@media (min-width: 768px) {
  .common-contact__tit {
    font-size: calc(2rem + 18 * (100vw - 1000px) / 540);
  }
}

@media (min-width: 1637px) {
  .common-contact__tit {
    font-size: 3rem;
  }
}

.common-contact__tel {
  color: #000;
  font-family: 'Montserrat', sans-serif;
  position: relative;
  font-size: calc(2rem + 4 * (100vw -  376px) / 1164);
  font-weight: 700;
  letter-spacing: 0;
}

.common-contact__tel:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
  .common-contact__tel {
    font-size: calc(1.5rem + 6 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .common-contact__tel {
    font-size: 2.25rem;
  }
}

.common-contact__tel::before {
  margin-right: 0.2em;
  font-size: 0.78em;
  color: #1D2087;
}

@media (min-width: 576px) {
  .common-contact__tel::before {
    font-size: 0.73em;
  }
}

@media (min-width: 1057px) {
  .common-contact__tel::before {
    font-size: 0.68em;
  }
}

.common-contact__tel a {
  color: #000 !important;
}

.common-contact__btn {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media (min-width: 768px) {
  .common-contact__btn {
    max-width: 360px;
  }
}

@media (max-width: 767px) {
  .common-contact__btn {
    margin-top: 0.5rem;
  }
}

.common-contact__btn a {
  display: block;
  width: 100%;
  padding: 1em 2%;
  background: #1D2087;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

@media (min-width: 768px) {
  .common-contact__btn a {
    font-size: calc(0.8rem + 2 * (100vw - 768px) / 772);
  }
}

@media (min-width: 1637px) {
  .common-contact__btn a {
    font-size: 1rem;
  }
}

.common-contact__btn a:hover {
  background: #120CBC;
}

.common-contact__btn a span:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.common-contact__btn a span::before {
  font-size: 1.2em;
  margin-right: 0.2em;
}

.common-contact__line {
  background: #F7F9FB;
  padding: 1em 2em;
}

@media (min-width: 768px) {
  .common-contact__line {
    width: 29.69%;
  }
}

.common-contact__line img {
  margin-left: auto;
  margin-right: auto;
}

/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
footer {
  color: #fff;
  background: #0E0F53;
}

.f-inner {
  padding: clamp(3rem, 6vw, 6rem) 0;
}

@media (min-width: 1637px) {
  .f-inner {
    padding: 6.5rem 0 5rem;
  }
}

@media (min-width: 1057px) {
  .f-flex {
    display: flex;
    justify-content: space-between;
  }
}

.f-info__tit {
  margin-bottom: 1rem;
  max-width: 280px;
}

@media (max-width: 767px) {
  .f-info__tit {
    width: 60%;
  }
}

.f-info__tit img {
  width: 100%;
}

.f-info address {
  line-height: 1.5;
  font-size: 0.81rem;
}

@media (min-width: 1057px) {
  .f-info address {
    font-size: 1rem;
  }
}

@media (min-width: 1057px) {
  .f-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 5%;
  }
}

@media (max-width: 1056px) {
  .f-nav {
    display: none;
  }
}

.f-nav__items:not(:last-child) {
  padding-right: 3rem;
}

.f-nav__items li {
  line-height: 1.5;
}

.f-nav__items li:not(:last-child) {
  margin-bottom: 0.75rem;
}

.f-nav__items a {
  color: #fff;
  font-size: 0.94rem;
  position: relative;
  text-decoration: none !important;
}

.f-nav__items a:hover {
  text-decoration: underline !important;
}

.f-sns {
  background: #EBF1FF;
}

.f-sns__inner {
  padding: clamp(1rem, 2vw, 2rem) 0;
  display: flex;
}

@media (min-width: 376px) {
  .f-sns__inner {
    align-items: center;
    column-gap: 1rem;
  }
}

@media (min-width: 1057px) {
  .f-sns__inner {
    column-gap: 2rem;
  }
}

@media (max-width: 375px) {
  .f-sns__inner {
    flex-direction: column;
    align-items: center;
    row-gap: 2.5vw;
  }
}

.f-sns__tit {
  line-height: 1;
  letter-spacing: 0.05em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.38rem;
  color: #1D2087;
}

@media (min-width: 376px) {
  .f-sns__tit {
    flex-shrink: 0;
    text-align: left;
    min-width: 100px;
    font-size: calc(1.14rem + 14 * (100vw -  376px) / 1164);
    width: auto;
  }
}

@media (min-width: 1637px) {
  .f-sns__tit {
    font-size: 2.13rem;
  }
}

.f-sns__list {
  display: flex;
  flex-wrap: wrap;
  column-gap: clamp(0.5em, 3.33%, 2.5rem);
}

@media (min-width: 376px) {
  .f-sns__list {
    flex-shrink: 1;
    width: calc(100% - 100px);
    max-width: 340px;
  }
}

@media (min-width: 576px) {
  .f-sns__list {
    column-gap: 6.66%;
  }
}

.f-sns__list li {
  width: 22.5%;
  max-width: 40px;
}

@media (min-width: 576px) {
  .f-sns__list li {
    max-width: 60px;
    width: 20%;
  }
}

@media (min-width: 1057px) {
  .f-sns__list li {
    width: 60px;
  }
}

.f-copy {
  padding: 1.5rem 0;
  font-size: 0.81rem;
  color: #fff;
}

.f-banner {
  background: #fff;
  padding: clamp(2rem, 4vw, 4rem) 0;
}

.f-banner__inner {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.f-banner__list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 2%;
  row-gap: 1.8vw;
}

@media (min-width: 768px) {
  .f-banner__list {
    column-gap: 1.92%;
    row-gap: 1.3vw;
  }
}

@media (min-width: 1057px) {
  .f-banner__list {
    row-gap: 25px;
  }
}

.f-banner__list > * {
  width: 49%;
}

@media (min-width: 768px) {
  .f-banner__list > * {
    width: 23.53%;
  }
}

.f-banner__list > * a {
  border: 1px solid #1D2087;
  display: block;
}

/* pagetop
--------------------------------------------------------------------*/
.pagetop {
  position: fixed;
  z-index: 6;
  bottom: 0;
  right: 0;
}

.pagetop a {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  font-size: 1.13rem;
  transition: all 0.3s ease 0s;
  color: #fff;
  background: #000;
}

.pagetop a:hover {
  background: #120CBC;
}

@media (min-width: 768px) {
  .pagetop a {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }
}

.pagetop i {
  vertical-align: top;
}

/*--------------------------------------------------------------------/
	column
/--------------------------------------------------------------------*/
/* column3 */
@media (min-width: 376px) {
  .l-col-3-xs {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.65%;
  }
  .l-col-3-xs > * {
    flex-basis: 48.67%;
  }
}

@media (min-width: 1057px) {
  .l-col-3-xs {
    column-gap: 2%;
  }
  .l-col-3-xs > * {
    flex-basis: 32%;
  }
}

@media (min-width: 1637px) {
  .l-col-3-xs {
    column-gap: 3.11%;
  }
  .l-col-3-xs > * {
    flex-basis: 31.25%;
  }
}

@media (max-width: 375px) {
  .l-col-3-xs > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .l-col-3-sm {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3.47%;
  }
  .l-col-3-sm > * {
    flex-basis: 48.26%;
  }
}

@media (min-width: 1057px) {
  .l-col-3-sm {
    column-gap: 4%;
  }
  .l-col-3-sm > * {
    flex-basis: 30.66%;
  }
}

@media (min-width: 1637px) {
  .l-col-3-sm {
    column-gap: 3.89%;
  }
  .l-col-3-sm > * {
    flex-basis: 30.73%;
  }
}

@media (max-width: 575px) {
  .l-col-3-sm > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 768px) {
  .l-col-3-md {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1.3%;
  }
  .l-col-3-md > * {
    flex-basis: 32.46%;
  }
}

@media (min-width: 1057px) {
  .l-col-3-md {
    column-gap: 4%;
  }
  .l-col-3-md > * {
    flex-basis: 30.66%;
  }
}

@media (min-width: 1637px) {
  .l-col-3-md {
    column-gap: 3.89%;
  }
  .l-col-3-md > * {
    flex-basis: 30.73%;
  }
}

@media (max-width: 767px) {
  .l-col-3-md > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1057px) {
  .l-col-3-lg {
    display: flex;
    flex-wrap: wrap;
    column-gap: 4%;
  }
  .l-col-3-lg > * {
    flex-basis: 30.66%;
  }
}

@media (min-width: 1637px) {
  .l-col-3-lg {
    column-gap: 3.89%;
  }
  .l-col-3-lg > * {
    flex-basis: 30.73%;
  }
}

@media (max-width: 767px) {
  .l-col-3-lg > * {
    margin-left: auto;
    margin-right: auto;
  }
}

/* column4 */
@media (min-width: 376px) {
  .l-col-4-xs {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.65%;
  }
  .l-col-4-xs > * {
    flex-basis: 48.67%;
  }
}

@media (min-width: 1057px) {
  .l-col-4-xs {
    column-gap: 2%;
  }
  .l-col-4-xs > * {
    flex-basis: 23.5%;
  }
}

@media (min-width: 1637px) {
  .l-col-4-xs {
    column-gap: 1.94%;
  }
  .l-col-4-xs > * {
    flex-basis: 23.54%;
  }
}

@media (min-width: 1825px) {
  .l-col-4-xs {
    column-gap: 2.89%;
  }
  .l-col-4-xs > * {
    flex-basis: 22.83%;
  }
}

@media (max-width: 375px) {
  .l-col-4-xs > * {
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 576px) {
  .l-col-4-sm {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.65%;
  }
  .l-col-4-sm > * {
    flex-basis: 48.67%;
  }
}

@media (min-width: 1057px) {
  .l-col-4-sm {
    column-gap: 2%;
  }
  .l-col-4-sm > * {
    flex-basis: 23.5%;
  }
}

@media (min-width: 1637px) {
  .l-col-4-sm {
    column-gap: 1.94%;
  }
  .l-col-4-sm > * {
    flex-basis: 23.54%;
  }
}

@media (min-width: 1825px) {
  .l-col-4-sm {
    column-gap: 2.89%;
  }
  .l-col-4-sm > * {
    flex-basis: 22.83%;
  }
}

@media (max-width: 375px) {
  .l-col-4-sm > * {
    margin-left: auto;
    margin-right: auto;
  }
}

/*--------------------------------------------------------------------/
	pagination
/--------------------------------------------------------------------*/
.prev-next {
  margin-left: auto;
  margin-right: auto;
  margin-top: clamp(2rem, 3vw, 3rem);
  width: 100%;
  display: flex;
  justify-content: center;
}

.prev-next > * {
  width: 50%;
}

.prev-next__next a {
  padding-left: 1.5em;
  position: relative;
}

.prev-next__next a:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.prev-next__next a::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.prev-next__prev {
  text-align: right;
}

.prev-next__prev a {
  padding-right: 1.5em;
  position: relative;
}

.prev-next__prev a:before {
  content: "";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.prev-next__prev a::before {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.prev-next a {
  text-decoration: none !important;
  font-weight: 600;
  transition: all 0.3s ease 0s;
  color: #232323;
}

.prev-next a::before {
  color: #1D2087;
  transition: all 0.3s ease 0s;
}

.prev-next a:hover {
  color: #120CBC;
}

.prev-next a:hover::before {
  color: #120CBC;
}

/*-- pagination --*/
.pagination {
  text-align: center;
  font-size: 0;
  margin-top: clamp(2rem, 3vw, 3rem);
  color: #1D2087;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  display: flex;
  letter-spacing: 0;
  justify-content: center;
}

.pagination::after::after {
  display: block;
  content: "";
  clear: both;
}

.pagination a {
  text-decoration: none;
  transition: all 0.3s ease 0s;
  color: #1D2087;
  border: 1px solid #1D2087;
  background: #fff;
}

.pagination a:hover {
  border-color: #120CBC;
  background: #120CBC;
  color: #fff;
}

.pagination .prev,
.pagination .next {
  background: #fff;
  color: #1D2087;
}

.page-numbers {
  padding: 0 0.75em;
  margin: 0 0.2rem;
  line-height: 1;
  letter-spacing: 0;
  transition: all 0.3s ease 0s;
  font-size: 0.88rem;
  color: #fff;
  vertical-align: top;
  min-width: 36px;
  min-height: 36px;
  display: inline-block;
  line-height: 36px;
}

.page-numbers.current {
  border: 1px solid #1D2087;
  background: #1D2087;
  color: #fff;
}

.page-numbers.dots {
  color: #979DA4;
}

.page-numbers i {
  font-size: 0.75em;
}

.nav-prev {
  float: left;
  margin-top: 24px;
  margin-top: 1.5rem;
}

.nav-next {
  float: right;
  margin-top: 24px;
  margin-top: 1.5rem;
}

/* print css
----------------------------------------------------------------*/
@media print {
  header {
    display: none;
  }
  nav#global {
    display: none;
  }
  footer {
    display: none;
  }
}
