/*--------------------------------------------------------------------/
	header
/--------------------------------------------------------------------*/
.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: z-index(header);
	width: 100%;
	background: $white;
	@include transition(all, 0.3, ease-in, 0);
	height: 64px;
	@media (min-width: 1417px) {
		height: 70px;
	}
	&.fixed {
		box-shadow: 0 0 10px rgba($black, 0.1);
	}
}

.h- {
	&inner {
		position: relative;
		@include m-a;
		padding-left: map-get($contmargin, sp);
		@include media-breakpoint-up(lg) {
			padding-left: map-get($contmargin, pc);
		}
	}
	&flex {
		@include flex-between;
		align-items: center;
	}
	&left {
		padding-top: 1rem;
		@include transition(all, 0.3, ease-in, 0);
		@media (min-width: 1417px) {
			padding-top: 0.6rem;
		}
	}
	&logo {
		display: inline-block;
		font-weight: normal;
		letter-spacing: 0;
		text-align: left;
		max-width: 160px;
	}
	&right {
		@include flex-end;
		align-items: center;
	}
}
