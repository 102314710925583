/*--------------------------------------------------------------------/
	pagination
/--------------------------------------------------------------------*/
.prev-next {
	@include m-a;
	margin-top: clamp(2rem, 3vw, 3rem);
	width: 100%;
	display: flex;
	justify-content: center;
	& > * {
		width: 50%;
	}
	&__next {
		a {
			padding-left: 1.5em;
			position: relative;
			@include icon(arrow-left);
			&::before {
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	&__prev {
		text-align: right;
		a {
			padding-right: 1.5em;
			position: relative;
			@include icon(arrow-right);
			&::before {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	a {
		@include dec-none;
		@include f-w(600);
		@include transition;
		color: $txt_c;
		&::before {
			color: $main_c;
			@include transition;
		}
		&:hover {
			color: $point_c;
			&::before {
				color: $point_c;
			}
		}
	}
}

/*-- pagination --*/
.pagination {
	text-align: center;
	font-size: 0;
	margin-top: clamp(2rem,3vw,3rem);
	color: $main_c;
	@include f-w(500);
	@include f-family(font02);
	display: flex;
	@include l-sp(0);
	justify-content: center;
	&::after {
		@include clearfix;
	}
	a {
		text-decoration: none;
		@include transition;
		color: $main_c;
		border: 1px solid $main_c;
		background: $white;
		&:hover {
			border-color: $point_c;
			background: $point_c;
			color: $white;
		}
	}
	& .prev,
	& .next {
		background: $white;
		color: $main_c;
	}
}

.page-numbers {
	padding: 0 0.75em;
	margin: 0 0.2rem;
	@include line-h(1);
	@include l-sp(0);
	@include transition;
	@include f-size(14);
	color: $white;
	vertical-align: top;
	min-width: 36px;
	min-height: 36px;
	display: inline-block;
	line-height: 36px;
	&.current {
		border: 1px solid $main_c;
		background: $main_c;
		color: $white;
	}
	&.dots {
		color: $gray;
	}
	i {
		font-size: 0.75em;
	}
}

.nav-prev {
	float: left;
	margin-top: 24px;
	margin-top: 1.5rem;
}

.nav-next {
	float: right;
	margin-top: 24px;
	margin-top: 1.5rem;
}
