
/*--------------------------------------------------------------------/
	body
/--------------------------------------------------------------------*/

html{
	font-size: 100%;
}
body {
	@include f-base;
	@include l-sp;
	@include set_font-family;
	@include line-h;
	@include f-w;
	word-wrap: break-word;
	color: $txt_c;
	background: $white;
	overflow-x: hidden;
}

/*
section:after,
article:after {
	content: "";
	clear: both;
	display: block;
}
*/

/* heading 
----------------------------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
	line-height: 1.4;
}

/* a,img
----------------------------------------------------------------*/
a{
	color:$link_c;
	vertical-align: top;
	&:link,&:visited {
		text-decoration: underline;
	}
	&:hover,&:active {
		text-decoration: none;
	}
	img {
		display:block;
		width: 100%;
	}
	&.hover {
		img {
			@include transition;
			&:hover {
				opacity: .6;
			}
		}
	} 
}

img {
	max-width: 100%;
	display: block;
}

/* tel-link */

.tel-link {
	a {
		@include dec-none;
	}
}

/* scroll-position */
[id*="link-"],
.scroll-position {
	padding-top:64px;
	margin-top:-64px;
	@include media-breakpoint-up(xl) {
		padding-top:6rem;
		margin-top:-6rem;
	}
}

/* scroll-bar */
.scroll-bar {
	&::-webkit-scrollbar {
		width: 8px;
	}
	&::-webkit-scrollbar-track {
		background:transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: $m-gray;
		border-radius: 8px;
		box-shadow: inset 0 0 0 1px $white;
	}
}
