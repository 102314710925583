/*----------------------------------------------------------------
	component(common)
----------------------------------------------------------------*/

/*	heading
/--------------------------------------------------------------------*/
.heading-tit {
	text-align: center;
	line-height: 1.4;
	padding-bottom: calc(0.5rem + 0.5em);
	margin-bottom: calc(2rem + 0.5em);
	position: relative;
	color: $black;
	@include f-w(700);
	@include f-s_xs(1.14, 6);
	@include media-breakpoint-up(xl) {
		@include f-size(24);
	}
	&::after {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		content: "";
		background-color: $main_c;
		width: 4rem;
		height:4px;
	}
}

.heading-tit2 {
	line-height: 1.4;
	border-bottom: 1px solid $m-gray;
	padding-bottom: calc(0.75rem + 0.3em);
	position: relative;
	margin-bottom: 1.5rem;
	@include f-w(400);
	@include f-s_xs(1.14, 2);
	@include media-breakpoint-up(xl) {
		@include f-size(20);
	}
	&::after {
		position: absolute;
		bottom: -1px;
		left: 0;
		content: "";
		background-color: $main_c;
		min-width: 5rem;
		width: 20%;
		height: 1px;
	}
}

.heading-tit4 {
	line-height: 1.4;
	position: relative;
	@include f-w(400);
	@include f-s_xs(1.14, 2);
	text-align: center;
	margin-bottom: 1.5rem;
	@include media-breakpoint-up(xl) {
		@include f-size(20);
	}
}

/* teble用のタイトル */
.table-tit {
	@extend .heading-tit;
}


/* ol
/--------------------------------------------------------------------*/
.common-ol {
	margin-left: 5px;
	counter-reset: ol-num;
	& > li {
		line-height: 1.4;
		padding-left: 1.5em;
		position: relative;
		&::before {
			position: absolute;
			left: 0;
			top: 0;
			counter-increment: ol-num;
			content: counter(ol-num) ".";
			padding-right: 0.5em;
			text-align: center;
		}
		&:not(:last-child) {
			margin-bottom: 0.7rem;
		}
	}
}

/* ul
/--------------------------------------------------------------------*/
.common-ul {
	text-align: left;
	& > li {
		line-height: 1.4;
		padding-left: 1.5rem;
		position: relative;
		&::before {
			content: "●";
			color: $main_c;
			position: absolute;
			left: 0;
			top: 0.2em;
			font-size: 0.8em;
			color: $main_c;
		}
		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
}
.common-ul2 {
	margin-left: 5px;
	text-align: left;
	& li {
		line-height: 1.4;
		padding-left: 1rem;
		position: relative;
		&::before {
			content: "・";
			position: absolute;
			color: $txt_c;
			left: 0;
			top: 0;
		}
		&:not(:last-child) {
			margin-bottom: 0.3rem;
		}
	}
}

.caution-ul {
	margin-left: 5px;
	& li {
		line-height: 1.4;
		padding-left: 1.2rem;
		position: relative;
		color: $red;
		&::before {
			content: "※";
			position: absolute;
			left: 0;
			top: 0;
		}
		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
}

/* dl
/--------------------------------------------------------------------*/
.common-dl {
	margin-left: 5px;
	margin-bottom: 2rem;
	& dt {
		line-height: 1.4;
		&:before {
			content: "●";
			padding-right: 0.3em;
		}
	}
	& dd {
		&:not(:last-child) {
			margin-bottom: 1.5rem;
		}
	}
}
.common-dl2 {
	margin-bottom: 1rem;
	& dt {
		line-height: 1.4;
		padding-bottom: 0.7rem;
		border-bottom: 1px solid $m-gray;
		margin-bottom: 0.7rem;
		@include f-em(18);
		@include f-w(500);
	}
	& dd {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}
}

/* table
----------------------------------------------------------------*/
.common-table {
	width: 100%;
	@include f-em(15);
	margin-bottom: 2rem;
	@include line-h(1.5);
	& tr {
		border-bottom: 1px solid $m-gray;
		&:first-child {
			border-top: 1px solid $m-gray;
		}
	}
	& th,
	& td.column-1 {
		text-align: left;
		@include f-w(600);
		padding: 1rem 0;
		@include media-breakpoint-up(md) {
			width: 25%;
			min-width: 180px;
		}
		@include media-breakpoint-up(xl) {
			width: 225px;
		}
		@include media-breakpoint-down(sm) {
			display: block;
			padding-bottom: .5rem;
		}
	}
	& td.column-2 {
		padding: 1rem 1.25rem 1rem 0.5rem;
		@include media-breakpoint-down(sm) {
			display: block;
			padding-top: 0;
			padding-left: 0;
		}
	}
}


/* table press CSS */
.tablepress td,
.tablepress th {
	padding: none;
}
.tablepress tbody td,
.tablepress tfoot th {
	border-top: none !important;
}

/*--------------------------------------------------------------------/
	common-parts
/--------------------------------------------------------------------*/

/* txt */
.txt {
	& p:not(:last-of-type) {
		margin-bottom: 1rem;
	}
}

/* align */
.align {
	&-right {
		float: right;
		margin: 0 0 0.7rem 0.7rem;
	}
	&-left {
		float: left;
		margin: 0.7rem 0.7rem 0 0;
	}
	&-center {
		display: block;
		margin: 0 auto 0.7rem;
	}
}
