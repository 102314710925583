/*--------------------------------------------------------------------/
	footer
/--------------------------------------------------------------------*/
footer {
	color: $white;
	background: $sub_c;
}

.f- {
	&inner {
		padding: clamp(3rem, 6vw, 6rem) 0;
		@include media-breakpoint-up(xl) {
			padding: 6.5rem 0 5rem;
		}
	}
	&flex {
		@include media-breakpoint-up(lg) {
			@include flex-between;
		}
	}
	&info {
		&__tit {
			margin-bottom: 1rem;
			max-width: 280px;
			@include media-breakpoint-down(sm) {
				width: 60%;
			}
			img {
				width: 100%;
			}
		}
		address {
			line-height: 1.5;
			@include f-size(13);
			@include media-breakpoint-up(lg) {
				@include f-size(16);
			}
		}
	}
	&nav {
		@include media-breakpoint-up(lg) {
			@include flex-wrap;
			padding-left: 5%;
		}
		@include media-breakpoint-down(md) {
			display: none;
		}
		&__items {
			&:not(:last-child) {
				padding-right: 3rem;
			}
			li {
				line-height: 1.5;
				&:not(:last-child) {
					margin-bottom: 0.75rem;
				}
			}
			a {
				color: $white;
				@include f-size(15);
				position: relative;
				@include dec-none;
				&:hover {
					@include dec-line;
				}
			}
		}
	}
	&sns {
		background: $other_c1;
		&__inner {
			padding: clamp(1rem, 2vw, 2rem) 0;
			display: flex;
			@include media-breakpoint-up(xs) {
				align-items: center;
				column-gap: 1rem;
			}
			@include media-breakpoint-up(lg) {
				column-gap: 2rem;
			}
			@include media-breakpoint-down(xxs) {
				flex-direction: column;
				align-items: center;
				row-gap: 2.5vw;
			}
		}
		&__tit {
			@include line-h(1);
			@include l-sp(0.05em);
			@include f-family(font02);
			@include f-w(700);
			@include f-size(22);
			color: $main_c;
			@include media-breakpoint-up(xs) {
				flex-shrink: 0;
				text-align: left;
				min-width: 100px;
				@include f-s_xs(1.14, 14);
				width: auto;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(34);
			}
		}
		&__list {
			@include flex-wrap;
			column-gap: clamp(0.5em, 3.33%, 2.5rem);
			@include media-breakpoint-up(xs) {
				flex-shrink: 1;
				width: calc(100% - 100px);
				max-width: 340px;
			}
			@include media-breakpoint-up(sm) {
				column-gap: 6.66%;
			}
			li {
				width: 22.5%;
				max-width: 40px;
				@include media-breakpoint-up(sm) {
					max-width: 60px;
					width: 20%;
				}
				@include media-breakpoint-up(lg) {
					width: 60px;
				}
			}
		}
	}
	&copy {
		padding: 1.5rem 0;
		@include f-size(13);
		color: $white;
	}
}

.f- {
	&banner {
		background: $white;
		padding: clamp(2rem, 4vw, 4rem) 0;
		&__inner {
			width: 100%;
			@include m-a;
		}
		&__list {
			@include flex-wrap;
			column-gap: 2%;
			row-gap: 1.8vw;
			@include media-breakpoint-up(md) {
				column-gap: 1.92%;
				row-gap: 1.3vw;
			}
			@include media-breakpoint-up(lg) {
				row-gap: 25px;
			}
			& > * {
				width: 49%;
				@include media-breakpoint-up(md) {
					width: 23.53%;
				}
				a {
					border: 1px solid $main_c;
					display: block;
				}
			}
		}
	}
}

/* pagetop
--------------------------------------------------------------------*/
.pagetop {
	position: fixed;
	z-index: z-index(pagetop);
	bottom: 0;
	right: 0;
	& a {
		width: 30px;
		height: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		@include dec-none;
		@include f-size(18);
		@include transition;
		color: $white;
		background: $black;
		&:hover {
			background: $point_c;
		}
		@include media-breakpoint-up(md) {
			width: 40px;
			height: 40px;
			@include f-size(24);
		}
	}
	i {
		vertical-align: top;
	}
}
