/*----------------------------------------------------------------
	contents
----------------------------------------------------------------*/
/* wrapper */

/* main */
.main {
	@include clearfix;
	padding-top: 64px;
	@media (min-width: 1417px) {
		padding-top: 70px;
	}
	img {
		display: block;
	}
}
/* contents */

.content {
	overflow: hidden;
}


.l-cont_lg {
	max-width: map-get($container-max-widths, lg); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
}
.l-cont {
	max-width: map-get($container-max-widths, xl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
}
.l-cont_xxl {
	max-width: map-get($container-max-widths, xxl); //メインコンテンツサイズ
	@include m-a;
	padding-left: map-get($contmargin, sp);
	padding-right: map-get($contmargin, sp);
	@include media-breakpoint-up(xl) {
		padding-left: map-get($contmargin, pc);
		padding-right: map-get($contmargin, pc);
	}
}

/* contents-box */
// Layout box
.l-box {
	width: 100%;
	z-index: 1;
	position: relative;
	&:not(:last-of-type) {
		margin-bottom: 8rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 10rem;
		}
	}
}

[class*="bg-box"] {
	padding: 2rem 4%;
	@include media-breakpoint-up(sm) {
		padding: 2rem 6%;
	}
}
.bg-box {
	&--gray {
		background: $p-gray;
	}
}

// common box
.news-box {
	position: relative;
	background: $white;
	margin-bottom: 1.5rem;
	text-align: left;
	@include media-breakpoint-up(lg) {
		margin-bottom: 2.5rem;
	}
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
	}
	&__date {
		@include f-family(font02);
		@include f-w(700);
		position: absolute;
		left: 0.7em;
		@include line-h(1);
		@include l-sp(0.05em);
		color: $main_c;
		bottom: calc(100% - 0.5em);
		z-index: z-index(module, part03);
		@include f-s_xs(1.5, 8);
		@include media-breakpoint-up(xl) {
			@include f-size(32);
		}
	}
	&__img {
		overflow: hidden;
		&__inner {
			@include aspect-img(4, 3);
		}
	}
	&__txt {
		background: $white;
		padding: 2.5rem 8% 2rem;
		color: $txt_c;
		position: relative;
	}
	&__category {
		position: absolute;
		left: 8%;
		top: -0.75rem;
		.category-item {
			padding: 0.5em 0.75em 0.4em;
			@include f-w(500);
			@include l-sp(0.05em);
			@include f-size(13);
		}
	}
	&__tit {
		@include f-s_xs(1, 3);
		margin-bottom: 0.5rem;
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

.l-col-4-xs {
	.news-box {
		@include media-breakpoint-up(xs) {
			@include f-s_xs(0.84, 4);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1, 2);
		}
	}
}
