@charset "utf-8";
@import "base";
@import "_foundations/foundations";
/* CSS Document */
/*====================================================================

	common.css

=====================================================================*/
@import "_components/loader";  //ローダー
@import "_components/header";  //ヘッダー
@import "_components/nav";     //ナビゲーション
@import "_components/common-contents";//コンテンツ
@import "_components/common-parts";  //共通css
@import "_components/common-contact";  //共通CVエリア

@import "_components/footer";  //フッター
@import "_components/column";  //カラム
@import "_components/pagination";  //ページネーション

/* print css
----------------------------------------------------------------*/
@media print {
	header {display:none;}
	nav#global{display: none;}
	footer {display:none;}
}