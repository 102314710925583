/*--------------------------------------------------------------------/
/* common-contact
---------------------------------------------------------------------*/
.common-contact {
	background: $other_c1;
	color: $txt_c;
	padding: clamp(3rem, 6vw, 6rem) 0;
	position: relative;
	&::before {
		position: absolute;
		top: 0;
		left: -20%;
		content: "";
		z-index: z-index(module,part04);
		width: 50%;
		height: 100%;
		transform: skewX(20deg);
		background: $other_c3;
		@include media-breakpoint-up(lg) {
			left: -25%;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module,part03);
		@include media-breakpoint-up(lg) {
			padding-left: 29.87%;
		}
	}
	&__img {
		position: absolute;
		z-index: z-index(module, part03);
		@include media-breakpoint-up(lg) {
			width: 23.24%;
			left: 0;
			top: 0;
		}
		@include media-breakpoint-down(md) {
			width: 30%;
			top: calc(100% + 2rem);
			right: 0;
		}
		@include media-breakpoint-down(sm) {
			width: clamp(100px, 28vw, 30%);
		}
	}
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			column-gap: 4.22%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 2rem;
		}
	}
	&__left {
		@include media-breakpoint-up(md) {
			width: 28.87%;
		}
	}
	&__info {
		text-align: center;
		@include media-breakpoint-up(md) {
			width: 33%;
		}
	}
	&__tit {
		@include f-family(font02);
		margin-bottom: 0.5rem;
		@include line-h(1);
		@include f-s_xs(2, 28);
		@include media-breakpoint-up(md) {
			@include f-s_lg(2, 18);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(48);
		}
	}
	&__tel {
		color: $black;
		@include f-family(font02);
		position: relative;
		@include icon(phone, before);
		@include f-s_xs(2, 4);
		@include f-w(700);
		@include l-sp(0);
		@include media-breakpoint-up(md) {
			@include f-s_md(1.5, 6);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(36);
		}
		&::before {
			margin-right: 0.2em;
			@include f-em(11);
			color: $main_c;
		}
		a {
			color: $black !important;
		}
	}
	&__btn {
		@include m-a;
		width: 100%;
		@include media-breakpoint-up(md) {
			max-width: 360px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 0.5rem;
		}
		a {
			display: block;
			width: 100%;
			padding: 1em 2%;
			background: $main_c;
			@include dec-none;
			@include transition;
			color: $white;
			@include f-w(700);
			text-align: center;
			@include media-breakpoint-up(md) {
				@include f-s_md(0.8, 2);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(16);
			}
			&:hover {
				background: $point_c;
			}
			span {
				@include icon(mail);
				&::before {
					font-size: 1.2em;
					margin-right: 0.2em;
				}
			}
		}
	}
	&__line {
		background: $o-white;
		padding: 1em 2em;
		@include media-breakpoint-up(md) {
			width: 29.69%;
		}
		img {
			@include m-a;
		}
	}
}
