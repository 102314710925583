@import "../_spnav/modal";
//@import "../_nav/sidr";
.global {
  @media (min-width: 1417px) {
    @include flex-end;
    align-items: center;
  }
  @media (max-width: 1416px)  {
    display: none;
  }
}
.g-nav {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.25em;
  @include media-breakpoint-up(xl) {
    padding-right: 2em;
  }
  & > li {
    &:not(:last-child) {
      margin-right: 1.25em;
      @include media-breakpoint-up(xl) {
        margin-right: 2em;
      }
    }
    & > a {
      vertical-align: middle;
      color: $txt_c;
      @include transition;
      @include dec-none;
      position: relative;
      @include f-w(700);
      @include l-sp(0.02em);
      padding-top: 7px;
      padding-bottom: 7px;
      @include media-breakpoint-up(lg) {
        @include f-s_lg(0.76, 2);
      }
      @include media-breakpoint-up(xl) {
        @include f-size(15);
      }
      &::before {
        content: "";
        background-color: $main_c;
        width: 0%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        @include transition;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
    .header.fixed & > a {
      color: $txt_c;
      &::before {
        background-color: $main_c;
      }
      &:hover {
        color: $main_c;
      }
    }
  }
  &__lv2 {
    position: relative;
    &:hover {
      & ul {
        top: 46px;
        visibility: visible;
        opacity: 1;
      }
    }
    & ul {
      position: absolute;
      @include transition;
      top: 10px;
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
      left: 0;
      z-index: 10;
      width: 240px;
      & a {
        display: block;
        @include dec-none;
        padding: 0.75rem 1rem;
        @include f-em(14) color: $green;
        line-height: 1.4;
        position: relative;
        background: $white;
        @include transition;
        &:hover {
          background: $b-gray;
          color: $white;
        }
      }
    }
  }
}

.g-nav__contact {
  height: 70px;
  a {
    @include dec-none;
    background: $main_c;
    color: $white;
    height: 100%;
    padding: 7px 1em;
    display: flex;
    align-items: center;
    text-align: center;
    @include f-w(700);
    @include transition;
    @include f-s_lg(0.8, 2);
    @include media-breakpoint-up(xl) {
      @include f-size(15);
      padding: 7px 2em;
    }
    &:hover {
      background: $point_c;
    }
    span {
      @include icon(mail);
      &::before {
        font-size: 1.1em;
        margin-right: 0.3em;
      }
    }
  }
}

//二階層メニュー
.pc-lv2 {
  position: relative;
  &:hover {
    & ul {
      top: 48px;
      visibility: visible;
      opacity: 1;
    }
  }
  & ul {
    position: absolute;
    @include transition;
    top: 30px;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    left: 0;
    z-index: 10;
    width: 240px;
    & a {
      display: block;
      @include dec-none;
      padding: 0.75rem 1rem;
      @include f-em(14) color: $white;
      line-height: 1.4;
      position: relative;
      background: $b-gray;
      @include transition;
      &:hover {
        background: $main_c;
        color: $white;
      }
    }
  }
}
